/* 
 * 1. Google Font
 */
//@import url(http://fonts.googleapis.com/css?family=Roboto:300,400,700);
/* 
 * 2.0 Global Style
 */
//  @font-face {
//   font-family: 'ariblk';
//   src: url('/fonts/Arial Black.woff')  format('truetype'); /* Safari, Android, iOS */
// }
// @import url('fonts/8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff2');
@font-face {
  font-family: 'Arial Black';
  src:  url('fonts/8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff2') format('woff2');
}
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap);


$themecolor : #6d1313;
 .themecolor{
  color: #6d1313;
 }
 .themecolor{
  background: $themecolor;
  border-color: $themecolor;
  color:#fff;
 }
html {
  font-family: 'Arial Black';
  font-size: 16px;
}

body {
  color: #4e4e4e;
  font-family: 'Arial Black';
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #000;
}
a:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

p {
  margin: 0 0 30px;
}

ul li {
  font-weight: 700;
  margin-bottom: 10px;
}
ul.social-block{
  margin-bottom: 0;
}
ul.social-block li{
  margin-bottom: 0;
}

.custom-list-icon, .list-arrow-right {
  list-style-type: none;
  padding: 0;
}
.custom-list-icon li, .list-arrow-right li {
  position: relative;
  padding-left: 20px;
}
.custom-list-icon li::before, .list-arrow-right li::before {
  position: absolute;
  left: 0;
  color: #4e4e4e;
  font-family: "Ionicons";
}

.social-block {
  list-style-type: none;
  padding: 0;
}
.social-block li {
  display: inline-block;
}
.social-block li a {
  display: inline-block;
  height: 32px;
  width: 32px;
  color: #4e4e4e;
}
.social-block li a i {
  font-size: 1.5em;
}
.social-block li a:hover, .social-block li a:focus {
  color: #000;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
}

.btn {
  border: 0;
  border-radius: 0;
  padding: 6px 28px;
  text-transform: uppercase;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
          transition-property: transform;
}
.btn:hover {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}

.form-control {
  border-radius: 0;
  box-shadow: none;
  height: 48px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 1.6;
}
.form-control:focus {
  box-shadow: none;
  border-color: #ccc;
  background-color: #f8f8f8;
}

.valign-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.section {
  padding: 70px 0;
}

.section-title {
  color: #313131;
  letter-spacing: 1px;
  margin: 0 0 20px;
  text-transform: uppercase;
  text-align: center !important;
}
/* 
 * 3.0 Helper Class
 */
/* 
 * 3.1 Helper Class: font-weight
 */
.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-700 {
  font-weight: 700;
}

/* 
 * 3.2 Helper Class: margin
 */
.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mt20 {
  margin-top: 20px;
}

/* 
 * 3.3 Helper Class: list
 */
.list-arrow-right li::before {
  content: "\f3d3";
}

/* 
 * 3.4 Helper Class: button
 */
.btn-white {
  color: #000;
  background-color: #fff;
}
.btn-theme {
  color: #fff;
  background-color: #6d1313;
}
.btn-theme:hover, .btn-theme:focus {
  color: #000;
  background-color: #fff;
}
.btn-white:hover, .btn-white:focus {
  color: #000;
  background-color: #fff;
}

.btn-black {
  color: #fff;
  background-color: #000;
}

.btn-black:hover, .btn-black:focus {
  color: #fff;
  background-color: #000;
}


/* 
 * 3.5 Helper Class: background
 */
.bg-image, .bg-image-1, .bg-image-2 {
  position: relative;
}
.bg-image::before, .bg-image-1::before, .bg-image-2::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-image-1 {
  background: url(../images/backgrounds/bg-1.jpg) no-repeat center center/cover;
}
.bg-image-1::before {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-image-2 {
  background: no-repeat center center/cover;
}
.bg-image-2::before {
  background-color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 768px) {
  .section {
    padding: 90px 0;
  }

  .section-title {
    color: #313131;
    letter-spacing: 1px;
    margin: 0 0 60px;
    text-transform: uppercase;
    text-align: left;
  }
}
/* 
 * 4.0 Navigation
 */
.navbar-custom {
  border: 0;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $themecolor;
  -webkit-transition: all 0.2s linear 0s;
          transition: all 0.2s linear 0s;
}
.navbar-custom .navbar-nav li {
  margin: 0;
}
.navbar-custom .navbar-nav > li > a {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid transparent;
}
.navbar-custom .navbar-nav > li > a:focus, .navbar-custom .navbar-nav > li > a:hover {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
}
.navbar-nav > li > a:focus span:after, .navbar-nav > li > a:hover span:after {
  background: url('../images/nav-caret.png') no-repeat center right;
  width: 1.4em;
  height: 1.4em;
  position: absolute;
  content: "";
  top:0;
  background-size: 1em 1em;
}
.navbar-custom .navbar-nav > .active > a {
  border-color: rgba(255, 255, 255, 0.5);
}

.navbar-toggle {
  border: 0;
  border-radius: 0;
  margin-top: 2px;
  float :left;
}
.navbar-toggle .icon-bar {
  background-color: $themecolor;
}

.navbar-solid {
  background-color: $themecolor !important;
  padding: 0 !important;
  -webkit-transition: all 0.2s linear 0s;
          transition: all 0.2s linear 0s;
}

.site-branding {
  float: left;
  margin-top: 0;
  margin-left: 10px;
}
.site-branding .logo {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-right: 5px;
  letter-spacing: 3px;
}
.site-branding .logo:focus, .site-branding .logo:hover {
  text-decoration: none;
}

/* 
 * 5.0 Site Header
 */
.site-header {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  text-align: center;
  background: no-repeat center center/cover;
  display: table;
}
.site-header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.site-header .intro {
  color: #fff;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
  padding: 0 15px;
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
}
.site-header .intro h2 {
  margin-top: 0px;
  font-size: 25px;
  font-weight: 300;
}
.site-header .intro h1 {
  margin-top: 0px;
  font-size: 30px;
  text-transform: uppercase;
}
.site-header .intro p {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 300;
}

@media (min-width: 768px) {
  .navbar-custom {
    background-color: transparent;
    padding-top: 30px;
  }

  .site-branding {
    margin-top: 6px;
    margin-left: 0;
  }
}
/* 
 * 6.0 Section
 */
/* 
 * 6.1 Section: About 
 */
.section-title {
  margin: 0 0 25px;
}

/* 
 * 6.2 Section: Facts
 */
.facts i {
  color: #fff;
  font-size: 3em;
}
.facts h3 {
  color: #fff;
  text-align: center;
  font-weight: 400;
}

/* 
 * 6.3 Section: Speaker
 */
.speaker {
  text-align: center;
  color: #313131;
  margin: 25px 0;
}
.speaker h4 {
  margin-top: 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.speaker p {
  margin-bottom: 5px;
}
.speaker img:hover {
  opacity: .7;
}

.speaker-new {
  text-align: center;
  color: #313131;
  margin: 20px;
}
.speaker-new h4 {
  margin-top: 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.speaker-new p {
  margin-bottom: 5px;
}
.speaker-new img{
  border-radius: 50%;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  cursor: pointer;
}
.speaker-new img:hover {  
  filter: none;
  -webkit-filter: none;
}

/* 
 * 6.4 Section: Registration & Pricing
 */
/* 
 * 6.5 Section: Contribution
 */
.contribution {
  text-align: center;
  color: #fff;
}

/* 
 * 6.6 Section: Schedule
 */
.schedule-box {
  position: relative;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 50px 0;
  margin: 25px 0 0;
}
.schedule-box::before {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 1px solid #fff;
}

/* 
 * 6.7 Section: Schedule
 */
.partner {
  // background-color: #f8f8f8;
}
.partner .partner-box {
  display: block;
  opacity: 0.3;
  height: 150px;
  width: 150px;
  margin: 25px auto;
  -webkit-transition: 0.5s all linear;
          transition: 0.5s all linear;
  background: no-repeat center center/contain;
}
.partner .partner-box:hover {
  opacity: 1;
}

/* 
 * 6.8 Section: FAQ
 */
.faq .panel-heading {
  cursor: pointer;
}
.faq .faq-toggle.collapsed::before {
  font-family: "Ionicons";
  content: "\f218";
  -webkit-transition: 0.5s linear ease-out;
          transition: 0.5s linear ease-out;
  position: absolute;
  left: 15px;
}
.faq .faq-toggle::before {
  position: absolute;
  left: 15px;
  font-family: "Ionicons";
  content: "\f209";
}
.faq .panel-group .panel + .panel {
  margin-top: 15px;
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}
.panel-group{
  margin-top: 2em;
}
.faq .panel-default > .panel-heading {
  color: #000;
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0;
  padding: 0;
}
.faq .panel-default > .panel-heading a {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 15px 15px 15px 35px;
  font-weight: normal;
  font-size:1.2em;
}

/* 
 * 6.9 Section: photos
 */
.grid {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.grid::after {
  content: '';
  display: block;
  clear: both;
}

.grid-item {
  float: left;
  overflow: hidden;
  padding: 3px;
  margin: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .grid-item-sm-6 {
    width: 50%;
  }

  .grid-item-sm-3 {
    width: 25%;
  }

  .section-title {
    margin: 0 0 30px;
  }

  figure {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .schedule-box {
    margin: 0;
  }

  .grid-item-md-6 {
    width: 50%;
  }

  .grid-item-md-3 {
    width: 25%;
  }
}
/* 
 * 7.0 Site-footer
 */

.site-title{
    font-weight: bold;
    font-size: 1.4em;
    margin-left: 10px;
}
.page-content{
    margin: 110px 0 15px 0;
}
.other-than-home-nav{
    background: $themecolor;
}
.invalid-feedback{
  color: red;
}

/* CSS for new theme */
.home-top{
  position: relative;
}

.home-top>.top-menu-container{
  position: absolute;
  width: 300px;
  top: 4em;
  z-index: 99999999;
}
.home-top>.top-menu-container ul{
  // display: block;
  width: 250px;
  position: relative;
  text-transform: uppercase;
}

.home-top>.top-menu-container ul li{
  display: block !important;
  width: 100%;
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 0;
}
.home-top>.top-menu-container ul li a{
  padding-bottom: 10px;
  padding-top: 5px;
  padding-left: 10px;
}

.home-top>.top-menu-container ul li:hover a{
  color: $themecolor;
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #fff !important;
}

.home-top-wrapper-page{
  width: 100%;
  display: block;
  position:relative;
  background-size: contain;
  background-position: center bottom;
}

.home-top-wrapper-page .home-top-right-wrapper-page{
  width: 80%;
  float: right;
  min-height: 20em;
  position: relative;
}
.home-top-wrapper-page .home-top-right-wrapper-page .theme-block-ball{
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background: $themecolor;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  --animate-duration: 3s;
}
.home-top-wrapper-page .home-top-right-wrapper-page .theme-block-text{
  width: 500px;
  height: 500px;
  position: absolute;
  z-index: 999;
  top: 20%;
  left: 25%; 
}
.home-top-wrapper-page .home-top-right-wrapper-page .theme-block-text>h1{
  font-size: 4.1em;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  margin-top:0;
  margin-bottom: 0;
  animation-duration: 2s;
}



.home-top-wrapper{
  width: 100%;
  display: block;
  position:relative;
  background: url('../images/backgrounds/home-top-background.png') no-repeat;
  background-size: contain;
  background-position: center bottom;
}

.home-top-wrapper .home-top-right-wrapper{
  width: 80%;
  float: right;
  min-height: 36em;
  position: relative;
}
.home-top-wrapper .home-top-right-wrapper .theme-block-ball{
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background: $themecolor;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  --animate-duration: 3s;
}
.home-top-wrapper .home-top-right-wrapper .theme-block-text{
  width: 500px;
  height: 500px;
  position: absolute;
  z-index: 999;
  top: 20%;
  left: 25%; 
}
.home-top-wrapper .home-top-right-wrapper .theme-block-text>h1{
  font-size: 4.1em;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  margin-top:0;
  margin-bottom: 0;
  animation-duration: 2s;
}
.home-top-wrapper .home-top-right-wrapper .theme-block-text>p{
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.6em;
}
h3.about-title{
  float:right;
  font-size: 2.1em;
  margin-top:0;
  margin-bottom: 0;
  font-weight: bold;
  font-family: 'Nunito Sans';
}
p.about-text{
  text-align: justify;
  font-size: 1.2em;
  font-family: 'Nunito Sans';
  color: #000;
}
.about-content{
  padding-top: 3em;
}
.about-content img{
  float: right;
  width: 40%;
  vertical-align: middle;
  margin-top: 2em;
  margin-right:3em;
}
.about{
  display: block;
  position:relative;
  background: url('../images/backgrounds/about_background.png') no-repeat;
  background-size: 60%;
  background-position: left bottom;
  min-height: 48em;
}
.arrow-right-full{
  display: block;
  position:relative;
  background: url('../images/backgrounds/arrow_right.png') no-repeat;
  background-size: contain;
  background-position: center right;
  height: 5em;
}
.small-arrow-right-full{
  display: block;
  position:relative;
  background: url('../images/backgrounds/small_arrow_right.png') no-repeat;
  background-size: contain;
  background-position: center right;
  height: 5em;
}
.small-arrow-left-full{
  display: block;
  position:relative;
  background: url('../images/backgrounds/small_arrow_left.png') no-repeat;
  background-size: contain;
  background-position: center left;
  height: 5em;
}
a.about-read-more-button{
  font-size:3em;
  font-weight: bold;
  float:left;
  text-decoration: none;
  display: block;
}
a.submit-here-button{
  font-size:2.4em;
  font-weight: bold;
  float:left;
  color:#444;
  text-decoration: none;
}
a.register-here-button{
  font-size:3em;
  font-weight: bold;
  float:left;
  color:$themecolor;
  text-align: center;
  text-decoration: none;
  text-align: center;
  width:100%;
}
a.about-read-more-button:hover{
  color: $themecolor;
}
a.submit-here-button:hover{
  color:$themecolor;
}
a.register-here-button:hover{
  color:$themecolor;
}
.speakers_new{
  width: 100%;
  background: url('../images/backgrounds/speakers_background.png') no-repeat $themecolor;
  display: block;
  position:relative;
  background-size: 80%;
  background-position: center 96%;
  padding-bottom: 18%;
}
h3.speakers-title{
  color:#fff;
  font-weight: bold;
  text-align: right;
  font-size:3em;
  margin-top:0;
  margin-bottom:0;
}
h3.speakers-subtitle{
  color:#fff;
  font-weight: bold;
  text-align: left;
  font-size:2em;
  margin-top:20px;
  margin-bottom:10px; 
}
.keynote-speakers{
  color: #fff;
}
.keynote-speakers figure{
  position: relative;
}
.keynote-speakers figure img{
  color: #313131;
  background:#555;
}
.keynote-speakers figure img{
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  cursor: pointer;
}
.keynote-speakers figure img:hover {  
  filter: none;
  -webkit-filter: none;
}
.keynote-speakers figure .image-overlay{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.keynote-speakers figure .image-overlay:hover+img{
  filter: none;
  -webkit-filter: none;
}
.keynote-speakers figure .image-overlay>.image-overlay-border{
  width:100%;
  height: 100%;
  border: 4px solid #fff;
}
.keynote-speakers figure .image-overlay>.image-overlay-border-vertical{
  position: absolute;
  z-index: 999;
  height: 100%;
  left: 24px;
  top:86%;
  height:8em;
  border-left: 4px solid #fff; 
}
.keynote-speakers .speakers-description{
  padding-left: 24px;
  padding-top: 15px;
  position:relative;
}
.keynote-speakers .speakers-description>h4.title{
  padding-left: 15px;
  margin-bottom: 4px;
}
.keynote-speakers .speakers-description>p.subtitle{
  padding-left: 15px;
}

.invited-speakers{
  color: #fff;
}
.invited-speakers figure{
  position: relative;
}
.invited-speakers figure img{
  color: #313131;
  background:#555;
}
.invited-speakers figure img{
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  cursor: pointer;
}
.invited-speakers figure img:hover {  
  filter: none;
  -webkit-filter: none;
}
.invited-speakers figure .image-overlay{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.invited-speakers figure .image-overlay:hover+img{
  filter: none;
  -webkit-filter: none;
}
.invited-speakers figure .image-overlay>.image-overlay-border{
  width:100%;
  height: 100%;
  border: 4px solid #000;
}
.invited-speakers figure .image-overlay>.image-overlay-border-vertical{
  position: absolute;
  z-index: 999;
  height: 100%;
  left: 24px;
  top:86%;
  height:8em;
  border-left: 4px solid #000; 
}
.invited-speakers .speakers-description{
  padding-left: 24px;
  padding-top: 15px;
  position: relative;
}
.invited-speakers .speakers-description>h4.title{
  padding-left: 15px;
  margin-bottom: 4px;
}
.invited-speakers .speakers-description>p.subtitle{
  padding-left: 15px;
}

.site-footer-new {
  background-color: $themecolor;
  color: #fff;
  text-align: center;
  padding: 60px 0 30px;
}
.site-footer-new .site-info {
  font-weight: 400;
  margin-bottom: 10px;
}
.site-footer-new .site-info a {
  color: #fff;
}
.site-footer-new .social-block li a {
  color: #fff;
}
.site-footer-new .social-block li a:hover, .site-footer-new .social-block li a:focus {
  opacity: 0.8;
}
h3.faq-title {
    float: right;
    font-size: 2.1em;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
}
.section-middle-title{
  font-size: 3em;
  color: $themecolor;
  text-align: center;
}
.contribution p{
  color:#000;
  font-size: 1.2em;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 10px dashed;
    border-top: 10px solid \9;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}
.work-text{
  font-family: 'Nunito Sans';
}
.jumbotron p{
  font-family: 'Nunito Sans';
}
.jumbotron{
  font-family: 'Nunito Sans';
}

@media screen and (max-width: 768px) {
  .home-top-wrapper-page .home-top-right-wrapper-page .theme-block-text>h1{
    font-size: 1.4em;
  }
  .home-top-wrapper .home-top-right-wrapper .theme-block-text>h1{
    font-size: 1.4em;
  }
  .home-top-wrapper .home-top-right-wrapper .theme-block-text > p {
      font-size: 0.9em;
  }
  .home-top-wrapper-page .home-top-right-wrapper-page .theme-block-text > p {
      font-size: 0.9em;
  }
  .home-top-wrapper .home-top-right-wrapper .theme-block-text{
    width: 250px;
    height: 250px;
    left: 25%; 
  }
  .home-top-wrapper-page .home-top-right-wrapper-page .theme-block-text{
    width: 250px;
    height: 250px;
    left: 25%; 
  }
  .arrow-right-full{
    height: 2em;
  }
  a.about-read-more-button{
    font-size:1.2em;
  }
  a.submit-here-button{
    font-size:2em;
    line-height: 2.4em;
  }
  a.register-here-button{
    font-size:2em;
    line-height: 2.4em;
  }
  .about{
    background:none;
  }
  .about-content img {
    display: block;
    margin:0 auto;
    float: none;
  }
  .home-top-wrapper .home-top-right-wrapper{
    min-height: 28em;
  }
  .speakers_new{
    background-image: none;

  }
  .home-top-wrapper-page .home-top-right-wrapper-page .theme-block-ball{
    width: 200px;
    height: 200px;
  }
  .home-top-wrapper .home-top-right-wrapper .theme-block-ball{
    width: 200px;
    height: 200px;
  }
  .home-top>.top-menu-container ul li{
    font-size: 1em;
    margin-left: 1em;
  }
}



/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999999999; /* Sit on top */
  left: 0;
  top: 150px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
/* Modal Header */
.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Body */
.modal-body {padding: 2px 16px;}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

/* The Close Button */
.close {
  color: #ff0000;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.member-title{
  color: $themecolor;
  padding-left: 1em;
}
.member-description{
  color: #555;
  padding-left: 0.8em;
  font-weight: normal;
}
.text-overlay{
  postition:relative;
  height: 100%;
}
.speakers-description .text-overlay>.hover-profile{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 999999;
  padding: 0px;
  padding-top: 0px;
  overflow: hidden;
  background: rgba(255,255,255,0);
  color: black;
  padding-top: 0;
  -webkit-transition: background 0.5s ease-in-out;
  -moz-transition: background 0.5s ease-in-out;
  -o-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
}
.invited-speakers:hover .text-overlay>.hover-profile{
  // display: block;
  height: auto;
  min-height: 130%;
  background: rgba(255,255,255,1);
}
.keynote-speakers:hover .text-overlay>.hover-profile{
  // display: block;
  height: auto;
  min-height: 130%;
  background: rgba(255,255,255,1);
}
.speakers-description .text-overlay>.hover-profile>p{
  text-align:justify;
  padding:10px 10px 0;
  font-size: 0.7em;
  font-weitht:normal;
}